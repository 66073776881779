<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h2 class="mt-5">Página no encontrada</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>